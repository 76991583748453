import React, { Component } from 'react'
import { css } from 'emotion';
import { colors, layout } from '../shared/theme'

import Section from '../components/Section'
import SectionIntro from '../components/SectionIntro'
import IconGrid from '../components/IconGrid'
import Button from '../components/Button'

class Features extends Component {
  state = {
    maxIcons: 6,
  }
  _handleSeeMore = () => {
    this.setState(state => ({
      maxIcons: state.maxIcons + 6,
    }))
  }
  render() {
    const { maxIcons } = this.state
    return (
      <div id="features">
        <Section
          color={colors.light}
          css={css`
            padding-bottom: 21rem;
            padding-top: 4rem;
            text-align: center;
            ${layout.bp.medium} {
              padding-top: 8rem;
              padding-bottom: 18rem;
            }
          `}
        >
          <SectionIntro heading={this.props.data.intro.heading} />
          <IconGrid items={this.props.data.icons.slice(0, maxIcons)} />
          {maxIcons < this.props.data.icons.length && (
            <Button onClick={this._handleSeeMore}>See more features</Button>
          )}
        </Section>
      </div>
    )
  }
}

export default Features
