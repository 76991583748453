import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';

import Button from '../Button';
import Container from '../Container';
import Pattern from '../Pattern';
import Float from '../Float';

import { colors, layout } from '../../shared/theme';
import { hexToRgba } from '../../shared/helpers';

const Banner = props => {
  const color = props.color || colors.blue;
  return (
    <div
      css={css`
        position: relative;
        margin: 0 1rem;
      `}
    >
      <a href="#contact" className="no-link-style">
        <Container css={containerStyle}>
          <BackgroundGrow color={color} />
          <div
            css={css`
              max-width: 600px;
            `}
          >
            <h2
              css={css`
                font-size: 28px;
                margin-top: 0;
                margin-bottom: 0.5em;
                ${layout.bp.medium} {
                  font-size: 35px;
                }
              `}
            >
              Features bespoke to your needs
            </h2>
            <p>
              Cloudlines is updated regularly. However, if you’d like to discuss
              specific features that you and your team needs; we can build
              functions and features just for you.
            </p>
            <Button color={colors.light} labelColor={color} tabIndex={-1}>
              Get in touch
            </Button>
          </div>
        </Container>
      </a>
      <Float
        style={`
        left: 7.5%;
        bottom 0;
        transform: translateY(50%);
      `}
      >
        <Pattern type="lines" color={colors.pink} />
      </Float>
      <Float
        style={`
        right: 8%;
        top: 0%;
        transform: translateY(8%);
      `}
      >
        <Pattern type="dots" color={hexToRgba(colors.dark, 0.25)} />
      </Float>
    </div>
  );
};

const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.light};
  max-width: 960px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  padding: 4rem 2rem;
  transform: translate(-50%, -50%);
  ${layout.bp.medium} {
    padding: 4rem;
  }
`;

const BackgroundGrow = styled('div')`
  background-color: ${props => props.color};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  box-shadow: 0px 23px 45px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  a:hover & {
    transform: scale(1.02, 1.02);
  }
`;

export default Banner;
