import React from 'react';
import RcTooltip from 'rc-tooltip';

import './tooltip.css';

const Tooltip = ({ children, ...props }) => (
  <RcTooltip {...props}>{children}</RcTooltip>
);

export default Tooltip;
