import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import { css } from 'emotion';
import { Transition, animated } from 'react-spring';
import RetinaImage from 'react-retina-image';
import { transparentize } from 'polished';
import { colors, layout } from '../../shared/theme';
import { MdChevronRight } from 'react-icons/md';

import Container from '../Container';

class TabbedCarousel extends Component {
  render() {
    const { items } = this.props;
    return (
      <div
        css={css`
          position: relative;
          padding-top: 2rem;
          overflow: hidden;
          background-color: ${this.props.color};
          ${layout.bp.medium} {
            padding-top: 5rem;
            height: 700px;
          }
        `}
      >
        <Container
          css={css`
            // padding-bottom: 3rem;
            position: relative;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 100%;
              display: none;
              ${layout.bp.medium} {
                position: absolute;
                width: 35%;
                display: block;
              }
            `}
          >
            {items.map((x, i) => (
              <div
                key={i}
                css={css`
                  border-bottom: 1px solid ${transparentize(0.9, colors.dark)};
                  padding: 1.5rem 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                  &:last-of-type {
                    border-bottom: 0;
                  }
                `}
                onClick={() => this.props.setCurrentTab(i)}
              >
                <div>
                  <h3
                    css={css`
                      color: ${i === this.props.currentTab
                        ? colors.primary
                        : transparentize(0.5, colors.dark)};
                      font-size: 1rem;
                      font-weight: 600;
                      user-select: none;
                      margin: 0;
                    `}
                  >
                    {x.heading}
                  </h3>
                  <p
                    css={css`
                      display: ${i === this.props.currentTab
                        ? 'block'
                        : 'none'};
                      padding-top: 1em;
                    `}
                  >
                    {x.body}
                  </p>
                </div>
                <div
                  css={css`
                    display: flex;
                    ${i === this.props.currentTab && `opacity: 0`};
                  `}
                >
                  <MdChevronRight
                    color={transparentize(0.8, colors.dark)}
                    size={30}
                  />
                </div>
              </div>
            ))}
          </div>
        </Container>
        <Transition
          items={this.props.currentTab}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0, position: 'absolute' }}
        >
          {(toggle, state, i) => props =>
            items.filter((item, i) => i === toggle).map((item, i) => (
              <animated.div
                key={i}
                style={props}
                css={css`
                  box-shadow: 0px 23px 45px 0px rgba(0, 0, 0, 0.1);
                  width: 100%;
                  position: relative;
                  display: none;
                  ${layout.bp.medium} {
                    position: absolute;
                    width: 800px;
                    left: 45%;
                    bottom: 0;
                    display: block;
                  }
                `}
              >
                <RetinaImage
                  forceOriginalDimensions={false}
                  src={withPrefix(item.image)}
                  style={{
                    display: 'block',
                  }}
                />
              </animated.div>
            ))}
        </Transition>
      </div>
    );
  }
}

export default TabbedCarousel;
