import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'emotion';

import Layout from '../components/Layout';
import HeroBanner from '../components/HeroBanner';
import Button from '../components/Button';
import Banner from '../components/Banner';
import ClientScroller from '../components/ClientScroller';
import Clients from '../components/Clients';
import Overview from '../components/Overview';
import Float from '../components/Float';
import Pattern from '../components/Pattern';
import Section from '../components/Section';
import ContentManager from '../components/ContentManager';
import Features from '../components/Features';
import Comparison from '../components/Comparison';
import Contact from '../components/Contact';
import FloatingIcons from '../components/FloatingIcons';

import { colors, layout } from '../shared/theme';
import { hexToRgba } from '../shared/helpers';

const IndexPage = ({ data }) => {
  const { home, site } = data;
  return (
    <Layout
      css={css`
        overflow-x: hidden;
      `}
    >
      <HeroBanner
        {...home.hero}
        button={
          <a
            href="#overview"
            css={css`
              text-decoration: none;
            `}
          >
            <Button float tabIndex={-1} solid color={colors.dark}>
              Learn more
            </Button>
          </a>
        }
      />
      <Section
        css={css`
          padding: 2rem 0;
          ${layout.bp.medium} {
            margin-top: 4rem;
            padding: 8rem 0;
          }
        `}
      >
        <Clients>
          <ClientScroller clients={site.clients} />
          <Float
            style={`
              left: 0;
              bottom 50%;
              transform: translateY(50%);
            `}
          >
            <Pattern type="dots" color={hexToRgba(colors.dark, 0.25)} />
          </Float>
        </Clients>
      </Section>
      <Overview data={home.overview} />
      <ContentManager />
      <Features data={home.features} />
      <Banner />
      <Comparison />
      <Contact />
      <FloatingIcons />
    </Layout>
  );
};

export const query = graphql`
  {
    site: globalJson {
      clients {
        name
        color
        image
        backgroundColor
      }
    }
    home: homeJson {
      hero {
        heading
        body
        image
      }
      overview {
        intro {
          heading
          body
        }
        tabs {
          heading
          tabHeading
          body
          image
        }
      }
      features {
        intro {
          heading
        }
        icons {
          heading
          body
          image
          color
          tooltip
        }
      }
    }
  }
`;

export default IndexPage;
