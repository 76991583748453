import React, { Component } from 'react';
import { css } from 'emotion';
import { hexToRgba } from '../../shared/helpers';
import Parallax from 'react-rellax';

const BaseIcon = ({ size, image, alt, shadow }) => (
  <div
    css={css`
      width: ${size || 80}px;
      height: ${size || 80}px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      pointer-events: none;
      ${shadow &&
        `
        box-shadow: 0px 15px 30px 0px ${hexToRgba(shadow, 0.3)};`} img {
        border-radius: 50%;
      }
    `}
  >
    <img alt={alt || 'icon'} src={image} />
  </div>
);

class Icon extends Component {
  constructor() {
    super();
    this.state = {
      windowWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    return this.props.float ? (
      <div
        style={this.props.style}
        css={css`
          position: absolute;
          z-index: 10;
          left: ${this.props.x ||
            Math.floor(Math.random() * this.state.windowWidth) + 'px'};
          top: ${this.props.y || Math.floor(Math.random() * 250 + 500) + 'px'};
          opacity: ${this.state.windowWidth ? 1 : 0};
          transition: opacity 0.3s;
        `}
      >
        <Parallax speed={Math.floor(Math.random() * -2 + 1)}>
          <BaseIcon {...this.props} />
        </Parallax>
      </div>
    ) : (
      <BaseIcon {...this.props} />
    );
  }
}

export default Icon;
