import React from 'react';
import { colors, layout } from '../shared/theme';
import { css } from 'emotion';

import Section from './Section';
import SectionIntro from './SectionIntro';
import Table from './Table';

const Comparison = () => (
  <Section
    color={colors.mid}
    css={css`
      padding-top: 20rem;
      padding-bottom: 6rem;
      ${layout.bp.medium} {
        padding-top: 18rem;
        padding-bottom: 8rem;
      }
    `}
  >
    <SectionIntro
      color={colors.mid}
      heading="How does it compare?"
      body="Cloudlines is a proven approach to brand management. It’s already being used by brands big and small to better manage their identity across all channels."
    />
    <Table />
  </Section>
);

export default Comparison;
