import React from 'react';
import { withPrefix } from 'gatsby';
import { css } from 'emotion';
import Container from '../Container';
import Icon from '../Icon';
import { colors, layout, animation } from '../../shared/theme';
import { Trail, animated } from 'react-spring';
import Observer from 'react-intersection-observer';
import Tooltip from '../Tooltip';

const isTrue = string => string && string !== 'false';

const IconGrid = ({ items }) => (
  <div
    css={css`
      background-color: ${colors.light};
    `}
  >
    <Observer threshold={0.3} triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>
          <Container
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Trail
              items={items}
              keys={item => item.image}
              from={{
                opacity: 0,
                transform: `translate3d(0, 50px, 0)`,
              }}
              to={{
                opacity: inView ? 1 : 0,
                transform: `translate3d(0, ${inView ? 0 : 50}px, 0)`,
              }}
              config={animation.spring}
              native
            >
              {(item, state, i) => styles => (
                <animated.div
                  css={css`
                    text-align: center;
                    padding: 2rem;
                    padding-top: 1rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 16px;
                    ${layout.bp.medium} {
                      width: 33.33%;
                    }
                    h3 {
                      font-size: 1rem;
                      font-weight: 600;
                      margin-top: 1em;
                      margin-bottom: 0.5em;
                    }
                  `}
                  style={styles}
                >
                  {item.image && (
                    <Icon
                      size={40}
                      image={withPrefix(item.image)}
                      shadow={colors[item.color]}
                    />
                  )}
                  {item.heading && (
                    <h3>
                      {item.heading}
                      {isTrue(item.tooltip) && (
                        <Tooltip
                          placement="top"
                          overlay={
                            <span
                              css={css`
                                width: 100px;
                              `}
                            >
                              {item.tooltip}
                            </span>
                          }
                        >
                          <div
                            css={css`
                              margin-left: 5px;
                              display: inline-block;
                              background: ${colors.dark};
                              opacity: 0.25;
                              width: 16px;
                              height: 16px;
                              margin-top: 1px;
                              vertical-align: top;
                              border-radius: 50%;
                              color: ${colors.light};
                              font-size: 10px;
                              line-height: 20px;
                              border: none;
                              user-select: none;
                            `}
                            href="#"
                          >
                            i
                          </div>
                        </Tooltip>
                      )}
                    </h3>
                  )}
                  {item.body && <p>{item.body}</p>}
                </animated.div>
              )}
            </Trail>
          </Container>
        </div>
      )}
    </Observer>
  </div>
);

export default IconGrid;
