import React from 'react';
import Icon from '../components/Icon';
import { css } from 'emotion';
import { colors, layout, animation } from '../shared/theme';
import { withPrefix } from 'gatsby';
import { Trail } from 'react-spring';

const icons = [
  {
    x: '24%',
    y: '620px',
    size: 57,
    image: withPrefix('/icons/Analytics.png'),
    shadow: colors.orange,
  },
  {
    x: '35%',
    y: '750px',
    size: 59,
    image: withPrefix('/icons/Search.png'),
    shadow: colors.green,
  },
  {
    x: '53%',
    y: '500px',
    size: 76,
    image: withPrefix('/icons/Share.png'),
    shadow: colors.blue,
  },
  {
    x: '65%',
    y: '630px',
    size: 51,
    image: withPrefix('/icons/Lock.png'),
    shadow: colors.primary,
  },
];

const FloatingIcons = () => (
  <div
    css={css`
      display: none;
      ${layout.bp.medium} {
        display: block;
      }
    `}
  >
    <Trail
      items={icons}
      keys={icon => icon.image}
      from={{
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
      }}
      to={{
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      }}
      config={animation.spring}
    >
      {(icon, state, i) => props => <Icon {...icon} style={props} float />}
    </Trail>
  </div>
);

export default FloatingIcons;
