import React, { Component } from 'react';
import { css } from 'emotion';

import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import TabbedCarousel from '../components/TabbedCarousel';
import Sweep from '../components/Sweep';
import { colors } from '../shared/theme';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: props.initialTab || 0,
    };
  }

  isCurrentTab = index => index === this.state.currentTab;

  setCurrentTab = index => {
    this.setState({
      currentTab: index,
    });
  };

  render() {
    const { data } = this.props;
    return (
      <div id="overview">
        <Sweep top={1000} />
        <Section
          color={colors.mid}
          css={css`
            position: relative;
            overflow: visible;
            padding-bottom: 0;
            margin-top: -8rem;
          `}
        >
          <SectionIntro
            heading={data.intro.heading}
            body={data.intro.body}
            color="transparent"
          />
        </Section>
        <TabbedCarousel
          items={data.tabs}
          currentTab={this.state.currentTab}
          setCurrentTab={this.setCurrentTab}
          color={colors.mid}
        />
      </div>
    );
  }
}

export default Overview;
