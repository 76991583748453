import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import { css } from 'emotion';
import Observer from 'react-intersection-observer';
import { Trail, animated } from 'react-spring';
import { colors, layout, animation } from '../shared/theme';
import RetinaImage from 'react-retina-image';

import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';

const panels = [
  <div key={0}>
    <div
      css={css`
        display: flex;
      `}
    >
      <RetinaImage src={withPrefix('/panels/New-page-modal.png')} />
      <RetinaImage src={withPrefix('/panels/Add-Images.png')} />
    </div>
    <RetinaImage src={withPrefix('/panels/Selective-Sharing.png')} />
  </div>,
  <div key={1}>
    <RetinaImage src={withPrefix('/panels/Sidebar.png')} />
  </div>,
  <div key={2}>
    <RetinaImage src={withPrefix('/panels/Page-list-panel.png')} />
    <RetinaImage src={withPrefix('/panels/Colour-pallete-panel.png')} />
  </div>,
  <div key={3}>
    <RetinaImage src={withPrefix('/panels/Right-Text-panel.png')} />
    <RetinaImage src={withPrefix('/panels/Analytics.png')} />
  </div>,
];

class ContentManager extends Component {
  constructor() {
    super();
    this.state = {
      inView: false,
    };
  }

  _handleInView = inView => {
    if (this.state.inView === inView) return;
    this.setState({ inView });
  };

  render() {
    return (
      <Observer
        tag="div"
        onChange={this._handleInView}
        threshold={0.5}
        triggerOnce
      >
        <Section
          color={colors.midPrimary}
          css={css`
            padding-top: 4rem;
            overflow: hidden;
            ${layout.bp.medium} {
              padding-top: 6rem;
            }
          `}
        >
          <SectionIntro
            heading="Create and organise your content with ease"
            body="Cloudlines is intuitive and really easy to use. You can add or update assets, pages and sections as your brand grows. You won’t need a web developer to do so—it’s as easy as writing an email."
            color={colors.midPrimary}
          />
          <div
            css={css`
              position: relative;
              width: 100%;
              transform: scale(0.35);
              height: 160px;
              ${layout.bp.medium} {
                height: 600px;
                transform: scale(0.95);
              }
            `}
          >
            <div
              css={css`
                display: flex;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                justify-content: space-between;
                ${layout.bp.medium} {
                  margin-top: 4rem;
                }
                img {
                  max-width: none;
                  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
                  border-radius: 5px;
                  margin: 10px;
                  padding: 0;
                  float: right;
                }
              `}
            >
              <Trail
                items={panels}
                keys={panel => panel.key}
                from={{
                  opacity: 0,
                  transform: 'translate3d(0, 100px, 0)',
                }}
                to={{
                  opacity: this.state.inView ? 1 : 0,
                  transform: this.state.inView
                    ? 'translate3d(0,0px,0)'
                    : 'translate3d(0, 100px, 0)',
                }}
                config={animation.spring}
              >
                {(panel, state, i) => props => (
                  <animated.div style={props}>{panel}</animated.div>
                )}
              </Trail>
            </div>
          </div>
        </Section>
      </Observer>
    );
  }
}

export default ContentManager;
