import React from 'react';
import { MdClear } from 'react-icons/md';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Container from '../Container';
import { colors } from '../../shared/theme';
import { css } from 'emotion';

const comparisonTable = css`
  width: 100%;
  font-size: 1rem;

  @include media(map-get($bp, 'large')) {
    font-size: 1rem;
  }

  td:not(:first-child) {
    width: 15%;
  }

  td {
    &:nth-child(3),
    &:nth-child(4) {
      opacity: 0.25;
    }
  }

  &,
  th,
  td {
    padding: 25px 10px;
    border-bottom: 1px solid rgba(233, 233, 235, 0.75);
    border-collapse: collapse;
    text-align: left;
  }

  td + td,
  th + th {
    text-align: center;
    font-weight: 300;
  }
`;

const Table = () => (
  <Container
    css={css`
      overflow-x: scroll;
    `}
  >
    <table css={comparisonTable}>
      <tbody>
        <tr>
          <th>Features</th>
          <th>Cloudlines</th>
          <th>Digital Asset Manager</th>
          <th>PDF / Printed Guidelines</th>
        </tr>
        <tr>
          <td>Always current and up-to-date</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <IoIosCheckmarkCircle size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Secure user access and management</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <IoIosCheckmarkCircle size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Descriptions and context alongside each part of the identity</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <MdClear size={25} />
          </td>
          <td>
            <IoIosCheckmarkCircle size={25} />
          </td>
        </tr>
        <tr>
          <td>Access from anywhere and from any device</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <MdClear size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Secure, shareable links direct to the content</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <IoIosCheckmarkCircle size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Edit content quickly and easily</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <MdClear size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Monitor popular content and user activity</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <MdClear size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
        <tr>
          <td>Upgradeable to fit your needs</td>
          <td>
            <IoIosCheckmarkCircle
              size={25}
              css={css`
                color: ${colors.primary};
              `}
            />
          </td>
          <td>
            <MdClear size={25} />
          </td>
          <td>
            <MdClear size={25} />
          </td>
        </tr>
      </tbody>
    </table>
  </Container>
);

export default Table;
